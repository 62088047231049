import "./App.css";
import { useState, useRef } from "react";
import axios from "axios";
import ResponseCard from "./responseCard/responseCard";
import ReactLoading from "react-loading";
import NavBar from "./navBar/navbar";
import logoC from "./assets/logoC.png";

function App() {
  const [short, setShort] = useState();
  const [response, setResponse] = useState();
  var [is_awaiting_response, set_is_awaiting_response] = useState(false);
  const [error, set_error] = useState(false);
  const myRef = useRef(null);

  const sortbackendData = (data) => {
    data = data.sort((a, b) => {
      a = a.answer;
      b = b.answer;
      let max_a = 0;
      let max_b = 0;
      for (let r of a) {
        if (parseInt(r.score) > max_a) max_a = parseInt(r.score);
      }
      for (let r of b) {
        if (parseInt(r.score) > max_b) max_b = parseInt(r.score);
      }
      console.log(max_a, max_b);
      return max_a > max_b ? -1 : 1;
    });
    console.log(data);
    return data;
  };
  const requestBackend = async () => {
    set_is_awaiting_response(true);
    var search_key = document.getElementById("input").value;

    await axios
      .get(`https://stackerbackendapi.onrender.com/explain`, {
        params: { search_key: search_key },
      })
      .then((res) => {
        if (res.status !== 200) return;
        setShort(res.data);
      })
      .catch((err) => {
        set_error(true);
        setResponse();
        set_is_awaiting_response(false);
      });

    //`https://stackerbackendapi.onrender.com/`
    //http://localhost:433/
    axios
      .get(`https://stackerbackendapi.onrender.com/scrape`, {
        params: { search_key: search_key },
      })
      .then((res) => {
        setResponse(sortbackendData(res.data));
        set_is_awaiting_response(false);
      })
      .catch((err) => {
        set_error(true);
        setResponse();
        set_is_awaiting_response(false);
      });
  };
  return (
    <div className="App">
      <NavBar />
      <div className="TopSectionWrapper">
        <img id="title" src={logoC} />
        <h3>An AI-powered Tool for Debugging Tasks</h3>
      </div>
      <div className="statsContainer">
        <div className="statInstanceContainer">
          <h3>450</h3>
          <label>VSCode Downloads</label>
        </div>
        <div className="statInstanceContainer">
          <h3>3,458</h3>
          <label>Total Requests</label>
        </div>
        <div className="statInstanceContainer">
          <h3>46</h3>
          <label>Requests Daily</label>
        </div>
      </div>
      <div id="inputContainer">
        <input
          placeholder="Paste Error Message and We Will Propose Fixes"
          id="input"
          onKeyDown={(e) => {
            if (e.key === "Enter") requestBackend();
          }}
        ></input>
        {!is_awaiting_response ? (
          <button
            id="Search"
            onClick={() => {
              requestBackend();
            }}
          >
            Search
          </button>
        ) : (
          <ReactLoading
            className="Loader"
            type="balls"
            color="#1C315E"
            height={50}
            width={50}
          />
        )}
      </div>
      {short ? (
        <div id="shortResContainer">
          <p>{short}</p>
        </div>
      ) : (
        ""
      )}
      {response ? (
        <div id="responseWrapper">
          <h3 id="addResourcesTitle">Additional Resources</h3>
          {response.map((res) => {
            return <ResponseCard params={res} />;
          })}
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div id="ErrorContainer">
          <span id="errorIcon" class="material-symbols-outlined">
            error
          </span>
          <h4>Sorry cannot find additional resources</h4>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;

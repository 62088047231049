import "./retired.css";

function Retired() {
  return (
    <div class="container">
      <h1>StackScraper Retired</h1>
      <p>
        Due to maintenance costs, this website has been retired and is no longer
        functional. During its 11 months of service, StackScraper reached a peak
        of 4,500 visitors. We extend our heartfelt thanks to everyone who used
        our product.
      </p>
      <p>
        If you have any questions or need assistance, please contact us at{" "}
        <a href="mailto:ata.kaboudi@gmail.com">here</a>.
      </p>
      <span>- Ata Kaboudi</span>
    </div>
  );
}

export default Retired;
